// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "IBM Plex Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Heading
$headings-font: "Roboto Slab", serif;
$headings-font-weight: 400;

$primary: #2EA567;
$success: #05ad3c;

$input-bg: #fff !default;

$min-contrast-ratio: 3 !default;