// Fonts
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&family=Roboto+Slab:wght@300;400;600&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.bg-alt {
    background-color: #228752;
}

.table thead th {
    border-bottom: 2px solid #eaeaea;
}

// slim select
@import 'slimselect';


.timelines {
    position: relative;
    list-style: none;
    padding-left: 23px;
    padding-top: 30px;
    padding-bottom: 10px;

    &::before {
        position: absolute;
        left: 10px;
        top: 0;
        background-color: var(--bs-primary);
        width:5px;
        content: '';
        display: block;
        height: 100%;
    }

    li {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 15px;
            left: -20px;
            width: 8px;
            box-sizing: unset;
            background: var(--bs-white);
            border-radius: 100%;
            height: 8px;
            border: 5px solid var(--bs-primary);
        }
    }

    .timeline-main {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        &:not(:last-child) {
            margin-bottom: 50px;
        }
        @include media-breakpoint-down(lg) {
            padding-left: 15px;
            margin-bottom: 20px;
        }
        .timeline-time {
            flex: 0 0 140px;
            text-align: right;
            padding: 10px 15px 10px 0px;
            flex-direction: column;

            @include media-breakpoint-down(lg) {
                text-align: left;
            }
            .time {
                font-size: 20px;
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            .date {
                font-size: 14px;
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    font-weight: normal;
                }
            }

            @include media-breakpoint-down(lg) {
                flex: 0 0  100% !important;
                margin-bottom: 0px;
            }
        }
        
        .timeline-body {
            max-width: 450px;
            flex: 0 0  calc(100% - 140px);
            @include media-breakpoint-down(lg) {
                flex: 0 0  100% !important;
            }
        }
        .tracker-created-by {
            font-size: 13px;
        }
    }
}

.form-comment {
    max-width: 615px;
}

.table-vertical-middle tr td {
    vertical-align: middle;
}
.main-centered-app {
    min-height: calc(100vh - 150px);
}
.statistic-item {
    padding: 2px 10px;
    white-space: nowrap;
    &:not(:last-child):not(.no-border) {
        border-right: 1px solid #dee2e6 !important;
    }
    &:first-child {
        padding-left: 0 !important;
    }
    &:last-child {
        padding-right: 0 !important;
    }
}

.statistic-wrapper {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.form-select-categories {
    .ss-single-selected {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}
.break-all {
    word-break:  break-all !important;
}

@include media-breakpoint-down(md) {
    .report-title {
        font-size: 17px !important;
    }
}

.w-unset {
    width: unset !important;
}

.form-filter-range {
    @include media-breakpoint-down(md) {
        input {
            max-width: 90px;
        }
        .form-select-categories {
            width: 100% !important;
            padding-left: 0 !important;
            .ss-arrow {
                display: none !important;
            }
        }
    }
}
.search-waybill {
    @include media-breakpoint-up(sm) {
        min-width: 140px;
    }
}

.select-mini {
    .ss-single-selected {
        padding: 0.52rem .75rem !important;
        height: 31px !important;
    }
}

.mini-date-field {
    max-width: 120px !important;
}